import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Checkbox, Space, Typography, Button } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useLazyPassengerByPhoneNumberQuery } from 'src/graphql/queries/find-passenger-by-phone-number';
import 'src/components/passenger-form/styles/passengerForms.styles.less';
import { INewPassengerParams, PASSENGERT_TYPE } from 'src/types/passanger.type';
import { ITripSeatMap } from 'src/types/trip.type';
import { SEAT_STATUS } from 'src/types/seat-map.type';
import { NextOfKinFormFields } from 'src/components/passenger-form/components/NextOfKinFormFields';

const { Option } = Select;

export const FORM_TEST_ID = 'FORM_TEST_ID';

export interface IPassengerFormsProps {
  type: PASSENGERT_TYPE;
  passenger: INewPassengerParams;
  primaryPassenger?: INewPassengerParams;
  seatMap?: ITripSeatMap;
  selectedSeatIds?: number[];
  isFirst?: boolean;
  isCheckinAvailable: boolean;
  onGoBack?: () => void;
  onComplete?: (passenger: INewPassengerParams) => void;
}

export const PassengerForm: React.FC<IPassengerFormsProps> = (props) => {
  const {
    type,
    passenger,
    onComplete,
    seatMap,
    isCheckinAvailable,
    selectedSeatIds = [],
    primaryPassenger,
    isFirst = false,
    onGoBack,
  } = props;

  const [sameAsPrimary, setSameAsPrimary] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [fetchPassengerByPhoneNumber, { data }] = useLazyPassengerByPhoneNumberQuery();

  const onSetSameNextOfKin = (e: CheckboxChangeEvent) => {
    setSameAsPrimary(e.target.checked);
  };

  const onSubmit = (values: Omit<INewPassengerParams, 'trackingId'>) => {
    if (onComplete) {
      let passengerData: INewPassengerParams = {
        ...passenger,
        ...values,
      };

      if (sameAsPrimary && primaryPassenger) {
        passengerData = {
          ...passengerData,
          nextKinLastName: primaryPassenger.nextKinLastName,
          nextKinFirstName: primaryPassenger.nextKinFirstName,
          nextKinPhone: primaryPassenger.nextKinPhone,
        };
      }

      onComplete(passengerData);
    }
  };

  const onFindPassenger = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const phone = form.getFieldValue('phone');

    if (phone && phone.length >= 11) {
      fetchPassengerByPhoneNumber({ variables: { phone } });
    }
  };

  useEffect(() => {
    if (data?.getPassengerByPhone) {
      form.setFieldsValue({
        fullName: data.getPassengerByPhone.fullName,
        gender: data.getPassengerByPhone.gender,
        nextKinFirstName: data.getPassengerByPhone.nextKinFirstName,
        nextKinLastName: data.getPassengerByPhone.nextKinLastName,
        nextKinPhone: data.getPassengerByPhone.nextKinPhone,
      });
    } else {
      form.setFieldsValue({
        fullName: '',
        gender: '',
        nextKinFirstName: '',
        nextKinLastName: '',
        nextKinPhone: '',
      });
    }
  }, [data, form]);

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const selectableSeats =
    seatMap?.seats
      ?.filter((seat) => {
        return seat.status !== SEAT_STATUS.BLOCKED && seat.status !== SEAT_STATUS.OCCUPIED;
      })
      .map((seat) => seat) ?? [];

  return (
    <div className="container">
      <div className="collapse">
        <div className="form-container-card">
          <Form
            size="large"
            layout="vertical"
            onFinish={onSubmit}
            autoComplete="on"
            form={form}
            data-testid={FORM_TEST_ID}
          >
            {type !== PASSENGERT_TYPE.INFANT && (
              <>
                <Form.Item
                  label="Phone number"
                  name="phone"
                  rules={[{ required: true, message: 'Phone Number is required' }]}
                >
                  <Space.Compact style={{ width: '100%' }}>
                    <Input
                      placeholder="08000000092"
                      onChange={handlePhoneNumberChange}
                    />
                    <Button
                      type="primary"
                      onClick={onFindPassenger}
                      disabled={phoneNumber.length < 11}
                    >
                      Search
                    </Button>
                  </Space.Compact>
                </Form.Item>
                <Form.Item
                  label="Full Name"
                  name="fullName"
                  rules={[{ required: true, message: "Please input passenger's name" }]}
                >
                  <Input placeholder="John Doe" />
                </Form.Item>
                {isCheckinAvailable && (
                  <Form.Item
                    label="Seat Number"
                    name="seatId"
                    rules={[
                      {
                        required: passenger.type !== PASSENGERT_TYPE.INFANT,
                        message: "Please select passenger's seat number.",
                      },
                    ]}
                  >
                    <Select disabled={passenger.type === PASSENGERT_TYPE.INFANT} showSearch allowClear>
                      {selectableSeats.map((seat) => (
                        <Option
                          disabled={selectedSeatIds.includes(seat.id) && seat.id !== passenger.seatId}
                          className="flex space-between"
                          key={seat.id}
                          value={seat.id}
                        >
                          <Space>{seat.number}</Space>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </>
            )}
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please select passenger's gender." }]}
            >
              <Select showSearch allowClear placeholder="Male">
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Blood Group"
              name="bloodGroup"
              rules={[{ required: false, message: "Please select passenger's blood group." }]}
              hidden
            >
              <Select showSearch allowClear placeholder="O RhD Positive (O+)">
                <Option value="a+">A RhD positive (A+)</Option>
                <Option value="a-">A RhD negative (A-)</Option>
                <Option value="b+">B RhD positive (B+)</Option>
                <Option value="b-">B RhD negative (B-)</Option>
                <Option value="o+">O RhD positive (O+)</Option>
                <Option value="o-">O RhD negative (O-)</Option>
              </Select>
            </Form.Item>

            <div>
              <br />
              <Typography.Title level={5}>Next of kin information</Typography.Title>
            </div>
            {!passenger.isPrimaryPassenger && (
              <div className="checkbox">
                <Checkbox onChange={onSetSameNextOfKin}>Next of kin is same as primary passenger</Checkbox>
              </div>
            )}
            {!sameAsPrimary && (
              <NextOfKinFormFields form={form} />
            )}
            <div className="next">
              <Space>
                {!isFirst && (
                  <Button type="ghost" size="large" onClick={onGoBack}>
                    Previous
                  </Button>
                )}
                <Button type="primary" htmlType="submit" size="large">
                  Save & Continue
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
