import React from 'react';
import { Form, FormInstance, Input } from 'antd';

interface NextOfKinFormFieldsProps {
  form: FormInstance;
}

export const NextOfKinFormFields: React.FC<NextOfKinFormFieldsProps> = () => {
  return (
    <div className="kin-info-div">
      <Form.Item
        label="Last Name"
        name="nextKinLastName"
        rules={[{ required: true, message: 'Please input last name' }]}
      >
        <Input placeholder="Doe" />
      </Form.Item>
      <Form.Item
        label="First Name"
        name="nextKinFirstName"
        rules={[{ required: true, message: 'Please input first name' }]}
      >
        <Input placeholder="John" />
      </Form.Item>
      <Form.Item
        label="Phone Number"
        name="nextKinPhone"
        rules={[{ required: true, message: 'Phone number is required' }]}
      >
        <Input placeholder="08000000092" />
      </Form.Item>
    </div>
  );
};
